import { finalize, Observable, of, fromEvent, filter } from 'rxjs';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, InjectionToken, Injectable, input, output, ViewContainerRef, Directive, Inject, viewChild, effect, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { __decorate, __metadata } from 'tslib';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
const _c0 = ["container"];
class WlcmStreamUtils {
  static loadingDetector(subject) {
    subject.next(true);
    return function (source) {
      return source.pipe(finalize(() => subject.next(false)));
    };
  }
  static get emptySource() {
    return new Observable(subscriber => {
      subscriber.next();
      subscriber.complete();
    });
  }
}
class WlcmPaginatedDataEmulator {
  constructor(count, handler) {
    this.handler = handler;
    this.data = [];
    this.populateData(count);
  }
  load(params) {
    return of({
      currPage: params.page,
      totalPages: Math.ceil(this.data.length / params.limit),
      totalItems: this.data.length,
      data: this.data.slice((params.page - 1) * params.limit, params.page * params.limit)
    });
  }
  populateData(count) {
    this.data = Array.from(new Int8Array(count).fill(0)).map((_, index) => this.handler(index));
  }
}
var WlcmIconName;
(function (WlcmIconName) {
  WlcmIconName["SEARCH"] = "SEARCH";
  WlcmIconName["CALENDAR"] = "CALENDAR";
  WlcmIconName["CHEVRON_DOWN"] = "CHEVRON_DOWN";
})(WlcmIconName || (WlcmIconName = {}));
class WlcmOption {
  constructor(value, viewValue, data) {
    this.value = value;
    this.viewValue = viewValue;
    this.data = data;
  }
}
class WlcmAutocompleteOption extends WlcmOption {
  constructor(value, viewValue, searchValue, data) {
    super(value, viewValue, data);
    this.value = value;
    this.viewValue = viewValue;
    this.searchValue = searchValue;
    this.data = data;
  }
}
var Order;
(function (Order) {
  Order["ASC"] = "asc";
  Order["DESC"] = "desc";
})(Order || (Order = {}));
const DEFAULT_QUERY_PARAMS = {
  query: '',
  page: 1,
  limit: 10
};
class ChevronDownIconComponent {
  static {
    this.ɵfac = function ChevronDownIconComponent_Factory(t) {
      return new (t || ChevronDownIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChevronDownIconComponent,
      selectors: [["wlcm-chevron-down-icon"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 7,
      vars: 0,
      consts: [["width", "12px", "height", "7px", "viewBox", "0 0 12 7", "version", "1.1", "xmlns", "http://www.w3.org/2000/svg", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink"], ["id", "4-Media-Library", "stroke", "none", "stroke-width", "1", "fill", "none", "fill-rule", "evenodd"], ["id", "4.1.0-Filter-by-type", "transform", "translate(-303.000000, -73.000000)"], ["id", "Group-7", "transform", "translate(45.000000, 64.000000)"], ["id", "Group", "transform", "translate(258.000000, 6.000000)"], ["id", "Rectangle", "x", "0", "y", "0", "width", "12", "height", "12"], ["id", "Path", "stroke", "currentColor", "stroke-width", "2", "stroke-linecap", "round", "stroke-linejoin", "round", "points", "1 4 6 9 11 4"]],
      template: function ChevronDownIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0)(1, "g", 1)(2, "g", 2)(3, "g", 3)(4, "g", 4);
          i0.ɵɵelement(5, "rect", 5)(6, "polyline", 6);
          i0.ɵɵelementEnd()()()()();
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{display:block}[_nghost-%COMP%]   svg[_ngcontent-%COMP%]{display:block}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChevronDownIconComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-chevron-down-icon',
      standalone: true,
      imports: [CommonModule],
      template: "<svg\n  width=\"12px\"\n  height=\"7px\"\n  viewBox=\"0 0 12 7\"\n  version=\"1.1\"\n  xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n>\n  <g\n    id=\"4-Media-Library\"\n    stroke=\"none\"\n    stroke-width=\"1\"\n    fill=\"none\"\n    fill-rule=\"evenodd\"\n  >\n    <g id=\"4.1.0-Filter-by-type\" transform=\"translate(-303.000000, -73.000000)\">\n      <g id=\"Group-7\" transform=\"translate(45.000000, 64.000000)\">\n        <g id=\"Group\" transform=\"translate(258.000000, 6.000000)\">\n          <rect id=\"Rectangle\" x=\"0\" y=\"0\" width=\"12\" height=\"12\"></rect>\n          <polyline\n            id=\"Path\"\n            stroke=\"currentColor\"\n            stroke-width=\"2\"\n            stroke-linecap=\"round\"\n            stroke-linejoin=\"round\"\n            points=\"1 4 6 9 11 4\"\n          ></polyline>\n        </g>\n      </g>\n    </g>\n  </g>\n</svg>\n",
      styles: [":host{display:block}:host svg{display:block}\n"]
    }]
  }], null, null);
})();
class CalendarIconComponent {
  static {
    this.ɵfac = function CalendarIconComponent_Factory(t) {
      return new (t || CalendarIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CalendarIconComponent,
      selectors: [["wlcm-calendar-icon"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 0,
      consts: [["width", "16px", "height", "16px", "viewBox", "0 0 16 16", "version", "1.1", "xmlns", "http://www.w3.org/2000/svg", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink"], ["id", "icon/date-grey", "stroke", "none", "stroke-width", "1", "fill", "none", "fill-rule", "evenodd"], ["id", "Rectangle", "x", "0", "y", "0", "width", "16", "height", "16"], ["d", "M11,0 C11.5522847,0 12,0.44771525 12,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L4,1 C4,0.44771525 4.44771525,0 5,0 C5.55228475,0 6,0.44771525 6,1 L10,1 C10,0.44771525 10.4477153,0 11,0 Z M14,7 L2,7 L2,14 L14,14 L14,7 Z M4,3 L2,3 L2,5 L14,5 L14,3 L12,3 C12,3.55228475 11.5522847,4 11,4 C10.4477153,4 10,3.55228475 10,3 L6,3 C6,3.55228475 5.55228475,4 5,4 C4.44771525,4 4,3.55228475 4,3 Z", "id", "Combined-Shape", "fill", "#8E9498", "fill-rule", "nonzero"], ["id", "Rectangle", "fill", "#8E9498", "x", "9", "y", "9", "width", "3", "height", "3", "rx", "1"]],
      template: function CalendarIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0)(1, "g", 1);
          i0.ɵɵelement(2, "rect", 2)(3, "path", 3)(4, "rect", 4);
          i0.ɵɵelementEnd()();
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{display:block;cursor:pointer}[_nghost-%COMP%]   svg[_ngcontent-%COMP%]{display:block}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalendarIconComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-calendar-icon',
      standalone: true,
      imports: [CommonModule],
      template: "<svg\n  width=\"16px\"\n  height=\"16px\"\n  viewBox=\"0 0 16 16\"\n  version=\"1.1\"\n  xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n>\n  <g\n    id=\"icon/date-grey\"\n    stroke=\"none\"\n    stroke-width=\"1\"\n    fill=\"none\"\n    fill-rule=\"evenodd\"\n  >\n    <rect id=\"Rectangle\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"></rect>\n    <path\n      d=\"M11,0 C11.5522847,0 12,0.44771525 12,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L4,1 C4,0.44771525 4.44771525,0 5,0 C5.55228475,0 6,0.44771525 6,1 L10,1 C10,0.44771525 10.4477153,0 11,0 Z M14,7 L2,7 L2,14 L14,14 L14,7 Z M4,3 L2,3 L2,5 L14,5 L14,3 L12,3 C12,3.55228475 11.5522847,4 11,4 C10.4477153,4 10,3.55228475 10,3 L6,3 C6,3.55228475 5.55228475,4 5,4 C4.44771525,4 4,3.55228475 4,3 Z\"\n      id=\"Combined-Shape\"\n      fill=\"#8E9498\"\n      fill-rule=\"nonzero\"\n    ></path>\n    <rect\n      id=\"Rectangle\"\n      fill=\"#8E9498\"\n      x=\"9\"\n      y=\"9\"\n      width=\"3\"\n      height=\"3\"\n      rx=\"1\"\n    ></rect>\n  </g>\n</svg>\n",
      styles: [":host{display:block;cursor:pointer}:host svg{display:block}\n"]
    }]
  }], null, null);
})();
class SearchIconComponent {
  static {
    this.ɵfac = function SearchIconComponent_Factory(t) {
      return new (t || SearchIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SearchIconComponent,
      selectors: [["wlcm-search-icon"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 0,
      consts: [["width", "16px", "height", "16px", "viewBox", "0 0 16 16", "version", "1.1", "xmlns", "http://www.w3.org/2000/svg", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink", 2, "display", "block"], ["id", "icons/search", "stroke", "none", "stroke-width", "1", "fill", "none", "fill-rule", "evenodd"], ["id", "Rectangle", "x", "0", "y", "0", "width", "16", "height", "16"], ["d", "M7,0 C10.8659932,0 14,3.13400675 14,7 C14,8.57234957 13.4815869,10.0236173 12.6063605,11.1922034 L15.1213203,13.7071068 C15.5118446,14.0976311 15.5118446,14.7307961 15.1213203,15.1213203 C14.7307961,15.5118446 14.0976311,15.5118446 13.7071068,15.1213203 L11.1922034,12.6063605 C10.0236173,13.4815869 8.57234957,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2 Z", "id", "Combined-Shape", "fill", "currentColor", "fill-rule", "nonzero"]],
      template: function SearchIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0)(1, "g", 1);
          i0.ɵɵelement(2, "rect", 2)(3, "path", 3);
          i0.ɵɵelementEnd()();
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{display:block;color:#5a5a5a}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchIconComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-search-icon',
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<svg\n  width=\"16px\"\n  height=\"16px\"\n  viewBox=\"0 0 16 16\"\n  version=\"1.1\"\n  xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n  style=\"display: block\"\n>\n  <g\n    id=\"icons/search\"\n    stroke=\"none\"\n    stroke-width=\"1\"\n    fill=\"none\"\n    fill-rule=\"evenodd\"\n  >\n    <rect id=\"Rectangle\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"></rect>\n    <path\n      d=\"M7,0 C10.8659932,0 14,3.13400675 14,7 C14,8.57234957 13.4815869,10.0236173 12.6063605,11.1922034 L15.1213203,13.7071068 C15.5118446,14.0976311 15.5118446,14.7307961 15.1213203,15.1213203 C14.7307961,15.5118446 14.0976311,15.5118446 13.7071068,15.1213203 L11.1922034,12.6063605 C10.0236173,13.4815869 8.57234957,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2 Z\"\n      id=\"Combined-Shape\"\n      fill=\"currentColor\"\n      fill-rule=\"nonzero\"\n    ></path>\n  </g>\n</svg>\n",
      styles: [":host{display:block;color:#5a5a5a}\n"]
    }]
  }], null, null);
})();
const DEFAULT_WLCM_ICONS = {
  [WlcmIconName.SEARCH]: SearchIconComponent,
  [WlcmIconName.CALENDAR]: CalendarIconComponent,
  [WlcmIconName.CHEVRON_DOWN]: ChevronDownIconComponent
};
const WLCM_ICONS = new InjectionToken('WLCM_ICONS', {
  providedIn: 'root',
  factory: () => DEFAULT_WLCM_ICONS
});
class ScrollDetectionApi {
  onScrolledDown(element) {
    let scrollHeight = 0;
    let scrollHeightChanged = false;
    return fromEvent(element, 'scroll').pipe(filter(() => {
      if (scrollHeight !== element.scrollHeight) {
        scrollHeight = element.scrollHeight;
        scrollHeightChanged = true;
      }
      if (element.scrollHeight * 0.8 <= element.scrollTop + element.clientHeight && scrollHeightChanged) {
        scrollHeightChanged = false;
        return true;
      }
      return false;
    }));
  }
  static {
    this.ɵfac = function ScrollDetectionApi_Factory(t) {
      return new (t || ScrollDetectionApi)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ScrollDetectionApi,
      factory: ScrollDetectionApi.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollDetectionApi, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
let WlcmIconDirective = class WlcmIconDirective {
  constructor(icons, viewContainer) {
    this.icons = icons;
    this.viewContainer = viewContainer;
    this.name = input.required({
      alias: 'wlcmIcon'
    });
    this.stopPropagation = input(false, {
      alias: 'wlcmIconStopPropagation'
    });
    this.clicked = output({
      alias: 'wlcmIconClicked'
    });
    this.handleClick = ref => {
      fromEvent(ref.location.nativeElement, 'click', event => event).pipe(untilDestroyed(this)).subscribe(event => {
        if (this.stopPropagation()) event.stopPropagation();
        this.clicked.emit(event);
      });
    };
  }
  ngOnInit() {
    const component = this.icons[this.name()];
    if (component) {
      const ref = this.viewContainer.createComponent(component);
      this.handleClick(ref);
    }
  }
  static {
    this.ɵfac = function WlcmIconDirective_Factory(t) {
      return new (t || WlcmIconDirective)(i0.ɵɵdirectiveInject(WLCM_ICONS), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmIconDirective,
      selectors: [["", "wlcmIcon", ""]],
      inputs: {
        name: [i0.ɵɵInputFlags.SignalBased, "wlcmIcon", "name"],
        stopPropagation: [i0.ɵɵInputFlags.SignalBased, "wlcmIconStopPropagation", "stopPropagation"]
      },
      outputs: {
        clicked: "wlcmIconClicked"
      },
      standalone: true
    });
  }
};
WlcmIconDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Object, ViewContainerRef])], WlcmIconDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmIconDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmIcon]',
      standalone: true
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_ICONS]
    }]
  }, {
    type: i0.ViewContainerRef
  }], null);
})();
class CloseIconComponent {
  static {
    this.ɵfac = function CloseIconComponent_Factory(t) {
      return new (t || CloseIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CloseIconComponent,
      selectors: [["wlcm-close-icon"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 0,
      consts: [["width", "16px", "height", "16px", "viewBox", "0 0 16 16", "version", "1.1", "xmlns", "http://www.w3.org/2000/svg", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink"], ["id", "2-User-Management", "stroke", "none", "stroke-width", "1", "fill", "none", "fill-rule", "evenodd"], ["id", "2.0.1-Export-users-two-filters", "transform", "translate(-310.000000, -54.000000)"], ["id", "icons/close", "transform", "translate(310.000000, 54.000000)"], ["id", "Rectangle", "fill", "#EDF0F3", "x", "0", "y", "0", "width", "16", "height", "16", "rx", "4"], ["d", "M12,4 L4,12 M4,4 L12,12", "id", "Combined-Shape", "stroke", "#4A5056", "stroke-width", "2", "stroke-linecap", "round"]],
      template: function CloseIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "svg", 0)(1, "g", 1)(2, "g", 2)(3, "g", 3);
          i0.ɵɵelement(4, "rect", 4)(5, "path", 5);
          i0.ɵɵelementEnd()()()();
        }
      },
      dependencies: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CloseIconComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-close-icon',
      standalone: true,
      imports: [CommonModule],
      template: "<svg\n  width=\"16px\"\n  height=\"16px\"\n  viewBox=\"0 0 16 16\"\n  version=\"1.1\"\n  xmlns=\"http://www.w3.org/2000/svg\"\n  xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n>\n  <g\n    id=\"2-User-Management\"\n    stroke=\"none\"\n    stroke-width=\"1\"\n    fill=\"none\"\n    fill-rule=\"evenodd\"\n  >\n    <g\n      id=\"2.0.1-Export-users-two-filters\"\n      transform=\"translate(-310.000000, -54.000000)\"\n    >\n      <g id=\"icons/close\" transform=\"translate(310.000000, 54.000000)\">\n        <rect\n          id=\"Rectangle\"\n          fill=\"#EDF0F3\"\n          x=\"0\"\n          y=\"0\"\n          width=\"16\"\n          height=\"16\"\n          rx=\"4\"\n        ></rect>\n        <path\n          d=\"M12,4 L4,12 M4,4 L12,12\"\n          id=\"Combined-Shape\"\n          stroke=\"#4A5056\"\n          stroke-width=\"2\"\n          stroke-linecap=\"round\"\n        ></path>\n      </g>\n    </g>\n  </g>\n</svg>\n"
    }]
  }], null, null);
})();
class WlcmDefaultLoaderComponent {
  static {
    this.ɵfac = function WlcmDefaultLoaderComponent_Factory(t) {
      return new (t || WlcmDefaultLoaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmDefaultLoaderComponent,
      selectors: [["wlcm-default-loader"]],
      hostAttrs: [1, "wlcm-default-loader"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 13,
      vars: 0,
      consts: [[1, "spinner", "center"], [1, "spinner-blade"]],
      template: function WlcmDefaultLoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1)(2, "div", 1)(3, "div", 1)(4, "div", 1)(5, "div", 1)(6, "div", 1)(7, "div", 1)(8, "div", 1)(9, "div", 1)(10, "div", 1)(11, "div", 1)(12, "div", 1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDefaultLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-default-loader',
      host: {
        class: 'wlcm-default-loader'
      },
      standalone: true,
      imports: [CommonModule],
      template: "<div class=\"spinner center\">\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n  <div class=\"spinner-blade\"></div>\n</div>\n"
    }]
  }], null, null);
})();
const WLCM_LOADER_COMPONENT = new InjectionToken('WLCM_LOADER_COMPONENT', {
  providedIn: 'root',
  factory: () => WlcmDefaultLoaderComponent
});
class WlcmLoaderComponent {
  get isHidden() {
    return this.hidden();
  }
  constructor(loaderComponent) {
    this.loaderComponent = loaderComponent;
    this.hidden = input(false);
    this.container = viewChild('container', {
      read: ViewContainerRef
    });
    effect(() => {
      if (!this.container()) return;
      this.container().createComponent(this.loaderComponent);
    });
  }
  static {
    this.ɵfac = function WlcmLoaderComponent_Factory(t) {
      return new (t || WlcmLoaderComponent)(i0.ɵɵdirectiveInject(WLCM_LOADER_COMPONENT));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmLoaderComponent,
      selectors: [["wlcm-loader"]],
      viewQuery: function WlcmLoaderComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.container, _c0, 5, ViewContainerRef);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostAttrs: [1, "wlcm-loader"],
      hostVars: 2,
      hostBindings: function WlcmLoaderComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("wlcm-loader-hidden", ctx.isHidden);
        }
      },
      inputs: {
        hidden: [i0.ɵɵInputFlags.SignalBased, "hidden"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 0,
      consts: [["container", ""], [1, "wlcm-loader-container"]],
      template: function WlcmLoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵelementContainer(1, null, 0);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-loader',
      host: {
        class: 'wlcm-loader'
      },
      standalone: true,
      imports: [CommonModule],
      template: "<div class=\"wlcm-loader-container\">\n  <ng-container #container></ng-container>\n</div>\n"
    }]
  }], () => [{
    type: i0.Type,
    decorators: [{
      type: Inject,
      args: [WLCM_LOADER_COMPONENT]
    }]
  }], {
    isHidden: [{
      type: HostBinding,
      args: ['class.wlcm-loader-hidden']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CalendarIconComponent, ChevronDownIconComponent, CloseIconComponent, DEFAULT_QUERY_PARAMS, DEFAULT_WLCM_ICONS, Order, ScrollDetectionApi, SearchIconComponent, WLCM_ICONS, WlcmAutocompleteOption, WlcmIconDirective, WlcmIconName, WlcmLoaderComponent, WlcmOption, WlcmPaginatedDataEmulator, WlcmStreamUtils };
