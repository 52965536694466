import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { emailRegex } from '@core/constants/forms/validation-patterns.constants';
import { FormErrorType } from '@core/constants/forms/form-errors.constants';

export type FormErrors = { [key in FormErrorType]?: DefaultFormErrorFn };

export type DefaultFormErrorFn = (error?: ValidationErrors | never) => string;

export type CustomErrorMessages = { [formErrorKey: string]: string };

export class FormValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    if (control.value?.length > 0 && !new RegExp(emailRegex).test(control.value)) {
      return { [FormErrorType.EMAIL]: true };
    }

    return null;
  }

  static match(controlName: string, checkControlName: string, errType: FormErrorType): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors[errType]) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        checkControl?.setErrors({ [errType]: 'error' });
        return { [errType]: 'error' };
      }

      const checkControlErrors: ValidationErrors | null = <ValidationErrors | null>checkControl?.errors;
      if (checkControlErrors?.[errType] && Object.keys(checkControlErrors).length === 1) {
        checkControl?.setErrors(null);
      }

      return null;
    };
  }

  static pattern(regExp: RegExp, errName: FormErrorType = FormErrorType.PATTERN): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
      if (!value || typeof value !== 'string') return null;
      return regExp.test(value) ? null : { [errName]: { requiredPattern: regExp } };
    };
  }
}
