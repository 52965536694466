import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Injectable, InjectionToken, inject, Directive, Pipe, Inject, HostListener, forwardRef, ChangeDetectorRef, Optional, Input, ContentChild, ViewChild, HostBinding, EventEmitter, NgZone, Host, Output, input, output, ElementRef, contentChildren, computed, signal, effect, viewChildren, NgModule } from '@angular/core';
import { __decorate, __metadata } from 'tslib';
import * as i4 from '@angular/forms';
import { Validators, NgControl, ControlContainer, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Subject, Observable, takeUntil, merge, fromEvent, observeOn, asyncScheduler, BehaviorSubject, EMPTY, switchMap, take, tap, concatMap, map, filter, timer, first, of } from 'rxjs';
import * as i1$3 from '@wlcm/angular/core';
import { WlcmAutocompleteOption, DEFAULT_QUERY_PARAMS, WlcmIconName, WlcmIconDirective, WlcmLoaderComponent, ScrollDetectionApi } from '@wlcm/angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import * as i4$1 from '@angular/material/select';
import { MatOption, MAT_SELECT_CONFIG, MatSelect, MatSelectModule } from '@angular/material/select';
import * as i1$1 from '@angular/material/autocomplete';
import { MatAutocomplete, MatAutocompleteOrigin, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import { RxLet } from '@rx-angular/template/let';
import * as i3 from '@angular/material/core';
import * as i1$2 from '@angular/material/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { mergeWith } from 'lodash';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
const _c0 = ["*"];
const _c1 = ["defaultContainer"];
const _c2 = [[["wlcm-label"]], [["", "wlcmFormFieldCustomContainer", ""]], [["", "wlcmFormFieldHint", ""]], [["", "wlcmFormFieldPrefix", ""]], [["", "wlcmInput", ""]], [["", "wlcmFormFieldSuffix", ""]]];
const _c3 = ["wlcm-label", "[wlcmFormFieldCustomContainer]", "[wlcmFormFieldHint]", "[wlcmFormFieldPrefix]", "[wlcmInput]", "[wlcmFormFieldSuffix]"];
function WlcmFormFieldComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 0);
    i0.ɵɵlistener("click", function WlcmFormFieldComponent_div_2_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.focus());
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵprojection(3, 3);
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(4, 4);
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵprojection(6, 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("contains-children", ctx_r1.prefix);
    i0.ɵɵadvance(3);
    i0.ɵɵclassProp("contains-children", ctx_r1.suffix);
  }
}
function WlcmFormFieldComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "wlcm-error");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "errorsMapper");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 1, ctx_r1.control));
  }
}
const _c4 = a0 => ({
  $implicit: a0
});
function WlcmAutocompleteComponent_ng_container_3_mat_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    i0.ɵɵproperty("value", option_r2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r2.viewValue, " ");
  }
}
function WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 8);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.noResultsTemplate())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c4, ctx_r2.control.value));
  }
}
function WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, " No results found ");
  }
}
function WlcmAutocompleteComponent_ng_container_3_mat_option_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 7);
    i0.ɵɵtemplate(1, WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_1_Template, 1, 4, "ng-container")(2, WlcmAutocompleteComponent_ng_container_3_mat_option_2_Conditional_2_Template, 1, 0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", true);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r2.noResultsTemplate() ? 1 : 2);
  }
}
function WlcmAutocompleteComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, WlcmAutocompleteComponent_ng_container_3_mat_option_1_Template, 2, 2, "mat-option", 4)(2, WlcmAutocompleteComponent_ng_container_3_mat_option_2_Template, 3, 2, "mat-option", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const options_r4 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", options_r4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", options_r4.length === 0);
  }
}
const _c5 = [[["wlcm-checkbox"]]];
const _c6 = ["wlcm-checkbox"];
function WlcmAutocompleteSuffixComponent_ng_container_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("wlcmIcon", ctx_r0.WlcmIconName.CHEVRON_DOWN);
  }
}
function WlcmAutocompleteSuffixComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, WlcmAutocompleteSuffixComponent_ng_container_0_Conditional_1_Template, 1, 1, "ng-container", 1);
    i0.ɵɵelement(2, "wlcm-loader", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const loading_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, !loading_r2 ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("hidden", !loading_r2);
  }
}
const _c7 = a0 => ({
  focused: a0
});
function WlcmSelectComponent_Conditional_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function WlcmSelectComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-select-trigger");
    i0.ɵɵtemplate(1, WlcmSelectComponent_Conditional_2_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const selectComponent_r3 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.triggerTemplate())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c4, selectComponent_r3.selected));
  }
}
function WlcmSelectComponent_mat_option_3_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function WlcmSelectComponent_mat_option_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, WlcmSelectComponent_mat_option_3_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 5);
  }
  if (rf & 2) {
    const option_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.optionTemplate())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c4, option_r4));
  }
}
function WlcmSelectComponent_mat_option_3_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const option_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵtextInterpolate1(" ", option_r4.viewValue, " ");
  }
}
function WlcmSelectComponent_mat_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 6);
    i0.ɵɵtemplate(1, WlcmSelectComponent_mat_option_3_Conditional_1_Template, 1, 4, "ng-container")(2, WlcmSelectComponent_mat_option_3_Conditional_2_Template, 1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r1.selectOptionFormat() === "CompleteOption" ? option_r4 : option_r4.value);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r1.optionTemplate() ? 1 : 2);
  }
}
class WlcmErrorComponent {
  static {
    this.ɵfac = function WlcmErrorComponent_Factory(t) {
      return new (t || WlcmErrorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmErrorComponent,
      selectors: [["wlcm-error"]],
      hostAttrs: [1, "wlcm-error"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function WlcmErrorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmErrorComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-error',
      standalone: true,
      imports: [CommonModule],
      host: {
        class: 'wlcm-error'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\n"
    }]
  }], null, null);
})();
class FormStateHandlerService {
  constructor() {
    this._stateChanges$ = new Subject();
    this.stateChanges$ = this._stateChanges$.asObservable();
  }
  updateFormState() {
    this._stateChanges$.next();
  }
  static {
    this.ɵfac = function FormStateHandlerService_Factory(t) {
      return new (t || FormStateHandlerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FormStateHandlerService,
      factory: FormStateHandlerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormStateHandlerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const WLCM_ERRORS = new InjectionToken('WLCM_ERRORS');
const WLCM_PRIORITY_ERRORS = new InjectionToken('WLCM_PRIORITY_ERRORS', {
  providedIn: 'root',
  factory: () => new Set()
});
const WLCM_INPUT = new InjectionToken('WLCM_INPUT');
const WLCM_INPUT_BINDER = new InjectionToken('WLCM_INPUT_BINDER');
const WLCM_FORM_FIELD = new InjectionToken('WLCM_FORM_FIELD');
const WLCM_SELECT_CONFIG = new InjectionToken('WLCM_SELECT_CONFIG');
const WLCM_MAPS_API_ERROR = `Google Maps JavaScript API not loaded. Please ensure the following script is included in your HTML: <script src="https://maps.googleapis.com/maps/api/js?key=[apiKey]&libraries=places"></script>`;
const DEFAULT_PAGINATED_DATA = {
  totalPages: 1,
  totalItems: 1,
  currPage: 1,
  data: []
};
class WlcmDefaultPlacesApi {
  constructor() {
    this._autocompleteService = new google.maps.places.AutocompleteService();
    this._placesService = new google.maps.places.PlacesService(document.createElement('div'));
    if (typeof google === 'undefined') {
      throw new Error(WLCM_MAPS_API_ERROR);
    }
  }
  getPlacesPaginated(query) {
    return new Observable(subscriber => {
      if (query.length === 0) {
        subscriber.next({
          ...DEFAULT_PAGINATED_DATA
        });
        return subscriber.complete();
      }
      const request = {
        input: query
      };
      this._autocompleteService.getPlacePredictions(request, (predictions, status) => {
        const result = {
          ...DEFAULT_PAGINATED_DATA
        };
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          result.data = predictions.map(prediction => {
            return new WlcmAutocompleteOption(prediction.place_id, prediction.description, prediction.description, prediction);
          });
        }
        subscriber.next(result);
        subscriber.complete();
      });
    });
  }
  get placesService() {
    return this._placesService;
  }
  get autocompleteService() {
    return this._autocompleteService;
  }
  static {
    this.ɵfac = function WlcmDefaultPlacesApi_Factory(t) {
      return new (t || WlcmDefaultPlacesApi)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WlcmDefaultPlacesApi,
      factory: WlcmDefaultPlacesApi.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmDefaultPlacesApi, [{
    type: Injectable
  }], () => [], null);
})();
class WlcmPlacesApi {
  static {
    this.ɵfac = function WlcmPlacesApi_Factory(t) {
      return new (t || WlcmPlacesApi)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WlcmPlacesApi,
      factory: t => WlcmDefaultPlacesApi.ɵfac(t),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmPlacesApi, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useClass: WlcmDefaultPlacesApi
    }]
  }], null, null);
})();
const WLCM_FORM = new InjectionToken('WLCM_FORM');
const WLCM_FORM_PROVIDER = {
  provide: WLCM_FORM,
  useClass: FormStateHandlerService
};
const WLCM_FORM_CONTROL = new InjectionToken('WLCM_FORM_CONTROL');
const WLCM_FORM_CONTROL_PROVIDER = {
  provide: WLCM_FORM_CONTROL,
  useFactory: () => inject(WLCM_FORM, {
    optional: true,
    skipSelf: true
  })
};
class WlcmFormFieldCustomContainerDirective {
  constructor(elementRef) {
    this.elementRef = elementRef;
  }
  static {
    this.ɵfac = function WlcmFormFieldCustomContainerDirective_Factory(t) {
      return new (t || WlcmFormFieldCustomContainerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmFormFieldCustomContainerDirective,
      selectors: [["", "wlcmFormFieldCustomContainer", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormFieldCustomContainerDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmFormFieldCustomContainer]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class ErrorsMapperPipe {
  constructor(changeDetectorRef, errors, priorityErrors) {
    this.changeDetectorRef = changeDetectorRef;
    this.errors = errors;
    this.priorityErrors = priorityErrors;
    this.destroy$ = new Subject();
    this.currentError = null;
  }
  transform(control) {
    this.handleStatusChanges(control);
    this.retrieveFirstError(control.errors);
    return this.currentError;
  }
  retrieveFirstError(errors) {
    if (!errors) return;
    Object.keys(errors).forEach(key => !errors[key] && delete errors[key]);
    const errorsTypes = Object.getOwnPropertyNames(errors);
    const priorityType = errorsTypes.find(type => this.priorityErrors.has(type));
    const outputError = priorityType || errorsTypes[0];
    if (outputError) {
      this.currentError = this.errors[outputError]?.(errors[outputError]);
    }
  }
  handleStatusChanges(control) {
    this.destroy$.next();
    control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.retrieveFirstError(control.errors);
      this.changeDetectorRef.markForCheck();
    });
  }
  static {
    this.ɵfac = function ErrorsMapperPipe_Factory(t) {
      return new (t || ErrorsMapperPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16), i0.ɵɵdirectiveInject(WLCM_ERRORS, 16), i0.ɵɵdirectiveInject(WLCM_PRIORITY_ERRORS, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "errorsMapper",
      type: ErrorsMapperPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorsMapperPipe, [{
    type: Pipe,
    args: [{
      name: 'errorsMapper',
      standalone: true,
      pure: false
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_ERRORS]
    }]
  }, {
    type: Set,
    decorators: [{
      type: Inject,
      args: [WLCM_PRIORITY_ERRORS]
    }]
  }], null);
})();
class WlcmFormFieldPrefixDirective {
  constructor() {
    this.handlerClick = event => {
      event.stopPropagation();
    };
  }
  static {
    this.ɵfac = function WlcmFormFieldPrefixDirective_Factory(t) {
      return new (t || WlcmFormFieldPrefixDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmFormFieldPrefixDirective,
      selectors: [["", "wlcmFormFieldPrefix", ""]],
      hostBindings: function WlcmFormFieldPrefixDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function WlcmFormFieldPrefixDirective_click_HostBindingHandler($event) {
            return ctx.handlerClick($event);
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormFieldPrefixDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmFormFieldPrefix]',
      standalone: true
    }]
  }], null, {
    handlerClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class WlcmFormFieldSuffixDirective {
  static {
    this.ɵfac = function WlcmFormFieldSuffixDirective_Factory(t) {
      return new (t || WlcmFormFieldSuffixDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmFormFieldSuffixDirective,
      selectors: [["", "wlcmFormFieldSuffix", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormFieldSuffixDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmFormFieldSuffix]',
      standalone: true
    }]
  }], null, null);
})();
let WlcmFormFieldComponent = class WlcmFormFieldComponent {
  get isFocused() {
    return this.inputRef?.isFocused() ?? false;
  }
  get isRequired() {
    return this.control?.hasValidator(Validators.required);
  }
  get isInvalid() {
    return this.control?.invalid;
  }
  get isTouched() {
    return this.control?.touched;
  }
  get isDisabled() {
    return this.control?.disabled;
  }
  constructor(parentFormMember, changeDetectorRef) {
    this.parentFormMember = parentFormMember;
    this.changeDetectorRef = changeDetectorRef;
    this.displayErrorMessages = true;
  }
  ngAfterViewInit() {
    if (!this.inputRef && !this.customContainer) {
      throw new Error('Input element should be bound to the wlcmInput directive.');
    }
    this.handleStateChanges();
  }
  focus() {
    if (this.isFocused || this.isDisabled) return;
    this.inputRef?.focus();
  }
  get control() {
    return (this.controlContainer?.control || this.ngControl?.control) ?? null;
  }
  get inputContainer() {
    return this.customContainer?.elementRef || this.defaultContainer;
  }
  handleStateChanges() {
    const events = this.controlEvents;
    if (this.parentFormMember) {
      events.push(this.parentFormMember.stateChanges$);
    }
    merge(...events).pipe(untilDestroyed(this)).subscribe(() => this.changeDetectorRef.markForCheck());
  }
  get inputRef() {
    return this._inputRef?.get();
  }
  get controlEvents() {
    const events = [];
    if (this.inputRef) {
      events.push(this.inputRef.focus$, this.inputRef.blur$);
    }
    if (this.control) {
      events.push(this.control.statusChanges);
    }
    return events;
  }
  static {
    this.ɵfac = function WlcmFormFieldComponent_Factory(t) {
      return new (t || WlcmFormFieldComponent)(i0.ɵɵdirectiveInject(WLCM_FORM_CONTROL, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmFormFieldComponent,
      selectors: [["wlcm-form-field"]],
      contentQueries: function WlcmFormFieldComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgControl, 5);
          i0.ɵɵcontentQuery(dirIndex, ControlContainer, 5);
          i0.ɵɵcontentQuery(dirIndex, WLCM_INPUT, 5);
          i0.ɵɵcontentQuery(dirIndex, WlcmFormFieldPrefixDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, WlcmFormFieldSuffixDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, WlcmFormFieldCustomContainerDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ngControl = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.controlContainer = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputRef = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.prefix = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.suffix = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customContainer = _t.first);
        }
      },
      viewQuery: function WlcmFormFieldComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.defaultContainer = _t.first);
        }
      },
      hostAttrs: [1, "wlcm-form-field"],
      hostVars: 10,
      hostBindings: function WlcmFormFieldComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("wlcm-field-focused", ctx.isFocused)("wlcm-field-required", ctx.isRequired)("wlcm-field-invalid", ctx.isInvalid)("wlcm-field-touched", ctx.isTouched)("wlcm-field-disabled", ctx.isDisabled);
        }
      },
      inputs: {
        displayErrorMessages: "displayErrorMessages"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([WLCM_FORM_CONTROL_PROVIDER, {
        provide: WLCM_FORM_FIELD,
        useExisting: forwardRef(() => WlcmFormFieldComponent)
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c3,
      decls: 5,
      vars: 2,
      consts: [["defaultContainer", ""], ["class", "wlcm-field-container", 3, "click", 4, "ngIf"], ["class", "wlcm-field-error-container", 4, "ngIf"], [1, "wlcm-field-container", 3, "click"], [1, "wlcm-field-prefix"], [1, "wlcm-field-suffix"], [1, "wlcm-field-error-container"]],
      template: function WlcmFormFieldComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵprojection(0);
          i0.ɵɵprojection(1, 1);
          i0.ɵɵtemplate(2, WlcmFormFieldComponent_div_2_Template, 7, 4, "div", 1);
          i0.ɵɵprojection(3, 2);
          i0.ɵɵtemplate(4, WlcmFormFieldComponent_div_4_Template, 4, 3, "div", 2);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx.customContainer);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.control && ctx.displayErrorMessages);
        }
      },
      dependencies: [CommonModule, i1.NgIf, WlcmErrorComponent, ErrorsMapperPipe],
      changeDetection: 0
    });
  }
};
WlcmFormFieldComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Object, ChangeDetectorRef])], WlcmFormFieldComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormFieldComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-form-field',
      standalone: true,
      imports: [CommonModule, WlcmErrorComponent, ErrorsMapperPipe],
      host: {
        class: 'wlcm-form-field'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [WLCM_FORM_CONTROL_PROVIDER, {
        provide: WLCM_FORM_FIELD,
        useExisting: forwardRef(() => WlcmFormFieldComponent)
      }],
      template: "<ng-content select=\"wlcm-label\"></ng-content>\n\n<ng-content select=\"[wlcmFormFieldCustomContainer]\"></ng-content>\n\n<div\n  class=\"wlcm-field-container\"\n  (click)=\"focus()\"\n  #defaultContainer\n  *ngIf=\"!customContainer\"\n>\n  <div class=\"wlcm-field-prefix\" [class.contains-children]=\"prefix\">\n    <ng-content select=\"[wlcmFormFieldPrefix]\"></ng-content>\n  </div>\n\n  <ng-content select=\"[wlcmInput]\"></ng-content>\n\n  <div class=\"wlcm-field-suffix\" [class.contains-children]=\"suffix\">\n    <ng-content select=\"[wlcmFormFieldSuffix]\"></ng-content>\n  </div>\n</div>\n\n<ng-content select=\"[wlcmFormFieldHint]\"></ng-content>\n\n<div class=\"wlcm-field-error-container\" *ngIf=\"control && displayErrorMessages\">\n  <wlcm-error>{{ $any(control) | errorsMapper }}</wlcm-error>\n</div>\n"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WLCM_FORM_CONTROL]
    }]
  }, {
    type: i0.ChangeDetectorRef
  }], {
    displayErrorMessages: [{
      type: Input
    }],
    ngControl: [{
      type: ContentChild,
      args: [NgControl, {
        descendants: true
      }]
    }],
    controlContainer: [{
      type: ContentChild,
      args: [ControlContainer, {
        descendants: true
      }]
    }],
    _inputRef: [{
      type: ContentChild,
      args: [WLCM_INPUT]
    }],
    prefix: [{
      type: ContentChild,
      args: [WlcmFormFieldPrefixDirective]
    }],
    suffix: [{
      type: ContentChild,
      args: [WlcmFormFieldSuffixDirective]
    }],
    customContainer: [{
      type: ContentChild,
      args: [WlcmFormFieldCustomContainerDirective]
    }],
    defaultContainer: [{
      type: ViewChild,
      args: ['defaultContainer']
    }],
    isFocused: [{
      type: HostBinding,
      args: ['class.wlcm-field-focused']
    }],
    isRequired: [{
      type: HostBinding,
      args: ['class.wlcm-field-required']
    }],
    isInvalid: [{
      type: HostBinding,
      args: ['class.wlcm-field-invalid']
    }],
    isTouched: [{
      type: HostBinding,
      args: ['class.wlcm-field-touched']
    }],
    isDisabled: [{
      type: HostBinding,
      args: ['class.wlcm-field-disabled']
    }]
  });
})();
const WLCM_FORM_FIELD_INPUT_CLASS = 'wlcm-form-field-input';
class WlcmInputDirective {
  constructor(_elementRef) {
    this._elementRef = _elementRef;
    this.focus = () => this._elementRef.nativeElement.focus();
    this.isFocused = () => this._elementRef.nativeElement === document.activeElement;
    this.focus$ = fromEvent(this._elementRef.nativeElement, 'focus');
    this.blur$ = fromEvent(this._elementRef.nativeElement, 'blur');
    const element = _elementRef.nativeElement;
    if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
      element.classList.add(WLCM_FORM_FIELD_INPUT_CLASS);
    }
  }
  static {
    this.ɵfac = function WlcmInputDirective_Factory(t) {
      return new (t || WlcmInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmInputDirective,
      selectors: [["", "wlcmInput", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WLCM_INPUT,
        useFactory: () => {
          const input = inject(WlcmInputDirective, {
            self: true
          });
          const binder = inject(WLCM_INPUT_BINDER, {
            self: true,
            optional: true
          });
          return {
            get: () => binder ? binder.bind() : input
          };
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmInputDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmInput]',
      providers: [{
        provide: WLCM_INPUT,
        useFactory: () => {
          const input = inject(WlcmInputDirective, {
            self: true
          });
          const binder = inject(WLCM_INPUT_BINDER, {
            self: true,
            optional: true
          });
          return {
            get: () => binder ? binder.bind() : input
          };
        }
      }],
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class WlcmLabelComponent {
  constructor() {
    this.required = false;
  }
  get isRequired() {
    return this.required;
  }
  static {
    this.ɵfac = function WlcmLabelComponent_Factory(t) {
      return new (t || WlcmLabelComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmLabelComponent,
      selectors: [["wlcm-label"]],
      hostAttrs: [1, "wlcm-label"],
      hostVars: 2,
      hostBindings: function WlcmLabelComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("required", ctx.isRequired);
        }
      },
      inputs: {
        required: "required"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 0,
      consts: [[1, "wlcm-label-asterisk"]],
      template: function WlcmLabelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵtext(1, "*");
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(2);
        }
      },
      dependencies: [CommonModule],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmLabelComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-label',
      standalone: true,
      imports: [CommonModule],
      host: {
        class: 'wlcm-label'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<span class=\"wlcm-label-asterisk\">*</span>\n\n<ng-content></ng-content>\n"
    }]
  }], null, {
    required: [{
      type: Input
    }],
    isRequired: [{
      type: HostBinding,
      args: ['class.required']
    }]
  });
})();
let AutocompleteDirective = class AutocompleteDirective {
  constructor(zone, autocomplete) {
    this.zone = zone;
    this.autocomplete = autocomplete;
    this.panelScrolled = new EventEmitter();
    this._scrollHeight = 0;
    this._scrollHeightChanged = false;
    this.handleOpenEvent();
  }
  handleOpenEvent() {
    this.autocomplete.opened.pipe(untilDestroyed(this)).pipe(observeOn(asyncScheduler)).subscribe(() => this.zone.runOutsideAngular(() => this.handleScrolling()));
  }
  handleScrolling() {
    const panel = this.autocomplete.panel.nativeElement;
    this.setScrollHscrollHeight(panel.scrollHeight);
    fromEvent(panel, 'scroll').pipe(takeUntil(this.autocomplete.closed)).subscribe(() => {
      if (this._scrollHeight !== panel.scrollHeight) {
        this.setScrollHscrollHeight(panel.scrollHeight);
      }
      if (panel.scrollHeight * 0.8 <= panel.scrollTop + panel.clientHeight && this._scrollHeightChanged) {
        this.zone.run(() => this.panelScrolled.emit());
        this._scrollHeightChanged = false;
      }
    });
  }
  setScrollHscrollHeight(height) {
    this._scrollHeight = height;
    this._scrollHeightChanged = true;
  }
  static {
    this.ɵfac = function AutocompleteDirective_Factory(t) {
      return new (t || AutocompleteDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1$1.MatAutocomplete, 9));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AutocompleteDirective,
      selectors: [["", "wlcmAutocomplete", ""]],
      outputs: {
        panelScrolled: "panelScrolled"
      },
      standalone: true
    });
  }
};
AutocompleteDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [NgZone, MatAutocomplete])], AutocompleteDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutocompleteDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmAutocomplete]',
      standalone: true
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i1$1.MatAutocomplete,
    decorators: [{
      type: Host
    }, {
      type: Optional
    }]
  }], {
    panelScrolled: [{
      type: Output
    }]
  });
})();
class WlcmAutocomplete {
  static {
    this.ɵfac = function WlcmAutocomplete_Factory(t) {
      return new (t || WlcmAutocomplete)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmAutocomplete
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmAutocomplete, [{
    type: Directive
  }], null, null);
})();
const PANEL_CLASS$1 = 'wlcm-autocomplete-panel';
let WlcmAutocompleteComponent = class WlcmAutocompleteComponent extends WlcmAutocomplete {
  constructor(formField) {
    super();
    this.formField = formField;
    this.required = false;
    this.placeholder = '';
    this.selectOptionFormat = input('ValueOnly');
    this.noResultsTemplate = input();
    this.selected = output();
    this.control = new FormControl('');
    this._loadMore$ = new Subject();
    this._options$ = new BehaviorSubject([]);
    this.options$ = this._options$.asObservable();
    this.closed$ = new Subject();
    this._focusStream$ = new BehaviorSubject(EMPTY);
    this._blurStream$ = new BehaviorSubject(EMPTY);
    this._loading$ = new BehaviorSubject(false);
    this.focus$ = this._focusStream$.pipe(switchMap(stream => stream));
    this.blur$ = this._blurStream$.pipe(switchMap(stream => stream));
    this.loading$ = this._loading$.asObservable();
    this.queryParams = {
      ...DEFAULT_QUERY_PARAMS,
      limit: 15
    };
    this._paginatedData = null;
    this._reloadOptions$ = new Subject();
    this.handleLoadMoreEvent();
    this.handleControlValueChanges();
  }
  ngOnInit() {
    this.loadOptions(this.queryParams).subscribe();
  }
  ngAfterViewInit() {
    const container = this.formField.inputContainer;
    const origin = new MatAutocompleteOrigin(container);
    this.autocompleteTrigger.connectedTo = origin;
    this.attachFocusBlurListeners();
  }
  displayWith(value) {
    if (value instanceof Object) {
      return value.viewValue;
    }
    return value;
  }
  focus() {
    this.inputElement.nativeElement.focus();
  }
  isFocused() {
    return this.inputElement?.nativeElement === document.activeElement || this.autocomplete?.isOpen;
  }
  loadMore() {
    this._loadMore$.next();
  }
  writeValue(value) {
    this.control.setValue(value);
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  setDisabledState(isDisabled) {
    if (isDisabled) {
      return this.control.disable();
    }
    this.control.enable();
  }
  reloadOptions() {
    this.queryParams.page = 1;
    this._reloadOptions$.next();
  }
  blured() {
    if (!this.autocomplete.isOpen) this._touched?.();
    this.autocomplete.closed.pipe(take(1)).subscribe(() => this._touched?.());
  }
  validate(control) {
    if (!!this.control.value) {
      if (this.control.value instanceof Object) return null;
      return {
        ...control.errors,
        unselected: true,
        required: false
      };
    }
    return null;
  }
  registerOnValidatorChange(fn) {
    this._onValidatorChange = fn;
  }
  loadOptions(params) {
    this._loading$.next(true);
    return this.pullDataMethod({
      ...params,
      page: 1
    }).pipe(tap(paginatedData => {
      this._paginatedData = paginatedData;
      this._options$.next(paginatedData.data);
      this._loading$.next(false);
    }));
  }
  handleLoadMoreEvent() {
    this._loadMore$.pipe(concatMap(() => {
      if (this._paginatedData && this._paginatedData.currPage + 1 <= this._paginatedData.totalPages) {
        this.queryParams.page++;
        this._loading$.next(true);
        return this.pullDataMethod(this.queryParams).pipe(tap(paginatedData => {
          this._paginatedData = paginatedData;
          this._options$.next([...this._options$.value, ...paginatedData.data]);
          this._loading$.next(false);
        }));
      }
      return EMPTY;
    })).subscribe();
  }
  handleControlValueChanges() {
    merge(this.control.valueChanges, this._reloadOptions$.pipe(map(() => this.control.value))).pipe(untilDestroyed(this)).pipe(filter(value => {
      let _value = value ?? '';
      if (value instanceof Object && this.selectOptionFormat() === 'ValueOnly') _value = value.value;
      this._changed?.(_value);
      this.selected.emit(_value);
      return !(value instanceof Object);
    })).pipe(switchMap(value => timer(200).pipe(switchMap(() => this.loadOptions({
      ...this.queryParams,
      query: value
    }))))).subscribe();
  }
  attachFocusBlurListeners() {
    const voidFn = () => {};
    const inputFocus = fromEvent(this.inputElement.nativeElement, 'focus');
    const inputBlur = fromEvent(this.inputElement.nativeElement, 'blur');
    const focusObservables = [inputFocus, this.autocomplete.opened];
    const blurObservables = [inputBlur, this.autocomplete.closed];
    this._focusStream$.next(merge(...focusObservables).pipe(map(voidFn)));
    this._blurStream$.next(merge(...blurObservables).pipe(map(() => this.control?.markAsTouched())));
  }
  static {
    this.ɵfac = function WlcmAutocompleteComponent_Factory(t) {
      return new (t || WlcmAutocompleteComponent)(i0.ɵɵdirectiveInject(WLCM_FORM_FIELD));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmAutocompleteComponent,
      selectors: [["wlcm-autocomplete"]],
      viewQuery: function WlcmAutocompleteComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(WLCM_INPUT, 5, ElementRef);
          i0.ɵɵviewQuery(MatAutocompleteTrigger, 5);
          i0.ɵɵviewQuery(MatAutocomplete, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputElement = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.autocompleteTrigger = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.autocomplete = _t.first);
        }
      },
      hostAttrs: [1, "wlcm-autocomplete"],
      inputs: {
        pullDataMethod: "pullDataMethod",
        label: "label",
        required: "required",
        placeholder: "placeholder",
        selectOptionFormat: [i0.ɵɵInputFlags.SignalBased, "selectOptionFormat"],
        noResultsTemplate: [i0.ɵɵInputFlags.SignalBased, "noResultsTemplate"]
      },
      outputs: {
        selected: "selected"
      },
      exportAs: ["wlcmAutocomplete"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: {
          overlayPanelClass: PANEL_CLASS$1
        }
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmAutocompleteComponent),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmAutocompleteComponent),
        multi: true
      }, {
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const autocomplete = inject(WlcmAutocompleteComponent, {
            self: true
          });
          return {
            bind: () => autocomplete
          };
        }
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 9,
      consts: [["autocomplete", "matAutocomplete"], ["wlcmInput", "", "type", "text", 3, "blur", "matAutocomplete", "placeholder", "formControl"], ["wlcmAutocomplete", "", 3, "panelScrolled", "disableRipple", "displayWith", "hideSingleSelectionIndicator"], [4, "rxLet"], [3, "value", 4, "ngFor", "ngForOf"], [3, "disabled", 4, "ngIf"], [3, "value"], [3, "disabled"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function WlcmAutocompleteComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "input", 1);
          i0.ɵɵlistener("blur", function WlcmAutocompleteComponent_Template_input_blur_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.blured());
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(1, "mat-autocomplete", 2, 0);
          i0.ɵɵlistener("panelScrolled", function WlcmAutocompleteComponent_Template_mat_autocomplete_panelScrolled_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.loadMore());
          });
          i0.ɵɵtemplate(3, WlcmAutocompleteComponent_ng_container_3_Template, 3, 2, "ng-container", 3);
          i0.ɵɵpipe(4, "async");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const autocomplete_r5 = i0.ɵɵreference(2);
          i0.ɵɵproperty("matAutocomplete", autocomplete_r5)("placeholder", ctx.placeholder)("formControl", ctx.control);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disableRipple", true)("displayWith", ctx.displayWith)("hideSingleSelectionIndicator", true);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("rxLet", i0.ɵɵpipeBind1(4, 7, ctx.options$));
        }
      },
      dependencies: [CommonModule, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.AsyncPipe, MatAutocompleteModule, i1$1.MatAutocomplete, i3.MatOption, i1$1.MatAutocompleteTrigger, AutocompleteDirective, ReactiveFormsModule, i4.DefaultValueAccessor, i4.NgControlStatus, i4.FormControlDirective, WlcmInputDirective, RxLet],
      changeDetection: 0
    });
  }
};
WlcmAutocompleteComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [Object])], WlcmAutocompleteComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmAutocompleteComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-autocomplete',
      exportAs: 'wlcmAutocomplete',
      host: {
        class: 'wlcm-autocomplete'
      },
      standalone: true,
      imports: [CommonModule, MatAutocompleteModule, AutocompleteDirective, ReactiveFormsModule, WlcmFormFieldComponent, WlcmLabelComponent, WlcmInputDirective, RxLet],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: {
          overlayPanelClass: PANEL_CLASS$1
        }
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmAutocompleteComponent),
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmAutocompleteComponent),
        multi: true
      }, {
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const autocomplete = inject(WlcmAutocompleteComponent, {
            self: true
          });
          return {
            bind: () => autocomplete
          };
        }
      }],
      template: "<input\n  wlcmInput\n  type=\"text\"\n  [matAutocomplete]=\"autocomplete\"\n  [placeholder]=\"placeholder\"\n  [formControl]=\"control\"\n  (blur)=\"blured()\"\n/>\n\n<mat-autocomplete\n  #autocomplete=\"matAutocomplete\"\n  wlcmAutocomplete\n  [disableRipple]=\"true\"\n  [displayWith]=\"displayWith\"\n  [hideSingleSelectionIndicator]=\"true\"\n  (panelScrolled)=\"loadMore()\"\n>\n  <ng-container *rxLet=\"$any(options$ | async); let options\">\n    <mat-option *ngFor=\"let option of options\" [value]=\"option\">\n      {{ option.viewValue }}\n    </mat-option>\n\n    <mat-option [disabled]=\"true\" *ngIf=\"options.length === 0\">\n      @if (noResultsTemplate()) {\n        <ng-container\n          *ngTemplateOutlet=\"\n            noResultsTemplate()!;\n            context: { $implicit: control.value }\n          \"\n        ></ng-container>\n      } @else {\n        No results found\n      }\n    </mat-option>\n  </ng-container>\n</mat-autocomplete>\n"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_FORM_FIELD]
    }]
  }], {
    pullDataMethod: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    inputElement: [{
      type: ViewChild,
      args: [WLCM_INPUT, {
        read: ElementRef
      }]
    }],
    autocompleteTrigger: [{
      type: ViewChild,
      args: [MatAutocompleteTrigger]
    }],
    autocomplete: [{
      type: ViewChild,
      args: [MatAutocomplete]
    }]
  });
})();
let WlcmCheckboxComponent = class WlcmCheckboxComponent {
  constructor(changeDetectorRef, parentFormMember) {
    this.changeDetectorRef = changeDetectorRef;
    this.parentFormMember = parentFormMember;
    this.value = input();
    this.changed = new EventEmitter();
    this.control = new FormControl(false);
    this.validate = () => null;
    if (this.parentFormMember) {
      this.handleStateChange();
    }
  }
  handleChange(value) {
    this.changed.emit(value.checked);
    this._changed?.(value.checked);
    this._touched?.();
  }
  writeValue(value) {
    this.control.setValue(value);
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  registerOnValidatorChange(callback) {
    this._validatorChanged = callback;
  }
  setDisabledState(isDisabled) {
    if (isDisabled) {
      return this.control.disable();
    }
    this.control.enable();
  }
  get checked() {
    return this.control.value;
  }
  set checked(value) {
    this.control.setValue(value);
  }
  handleStateChange() {
    this.parentFormMember.stateChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this._validatorChanged?.();
      this.changeDetectorRef.markForCheck();
    });
  }
  static {
    this.ɵfac = function WlcmCheckboxComponent_Factory(t) {
      return new (t || WlcmCheckboxComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(WLCM_FORM_CONTROL, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmCheckboxComponent,
      selectors: [["wlcm-checkbox"]],
      hostAttrs: [1, "wlcm-checkbox"],
      inputs: {
        value: [i0.ɵɵInputFlags.SignalBased, "value"]
      },
      outputs: {
        changed: "changed"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([WLCM_FORM_CONTROL_PROVIDER, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmCheckboxComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmCheckboxComponent),
        multi: true
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      consts: [[3, "change", "formControl"]],
      template: function WlcmCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "mat-checkbox", 0);
          i0.ɵɵlistener("change", function WlcmCheckboxComponent_Template_mat_checkbox_change_0_listener($event) {
            return ctx.handleChange($event);
          });
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("formControl", ctx.control);
        }
      },
      dependencies: [CommonModule, MatCheckboxModule, i1$2.MatCheckbox, ReactiveFormsModule, i4.NgControlStatus, i4.FormControlDirective]
    });
  }
};
WlcmCheckboxComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ChangeDetectorRef, Object])], WlcmCheckboxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-checkbox',
      host: {
        class: 'wlcm-checkbox'
      },
      standalone: true,
      imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
      providers: [WLCM_FORM_CONTROL_PROVIDER, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmCheckboxComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmCheckboxComponent),
        multi: true
      }],
      template: "<mat-checkbox [formControl]=\"control\" (change)=\"handleChange($event)\">\n  <ng-content></ng-content>\n</mat-checkbox>\n"
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WLCM_FORM_CONTROL]
    }]
  }], {
    changed: [{
      type: Output
    }]
  });
})();
let WlcmCheckboxGroupComponent = class WlcmCheckboxGroupComponent {
  constructor() {
    this.checkboxes = contentChildren(WlcmCheckboxComponent);
    this.checkboxesMap = computed(() => {
      return new Map(this.checkboxes().map(checkbox => [checkbox.value(), checkbox]));
    });
    this.values = signal(new Set());
    this.checkboxesListUpdated = new Subject();
    this.validate = () => null;
    effect(() => this.handleCheckboxesToggle(this.checkboxes()));
  }
  ngAfterContentInit() {
    this.updateCheckboxes(this.values());
  }
  writeValue(value) {
    this.values.set(new Set(value));
    if (this.checkboxes()) this.updateCheckboxes(this.values());
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  registerOnValidatorChange(callback) {
    this._validatorChanged = callback;
  }
  updateControl(values) {
    this._changed?.(Array.from(values.values()));
    this._touched?.();
  }
  updateCheckboxes(values) {
    const checkboxesMap = this.checkboxesMap();
    for (const checkbox of checkboxesMap.values()) {
      checkbox.checked = values.has(checkbox.value());
    }
  }
  handleCheckboxesToggle(checkboxes) {
    this.checkboxesListUpdated.next();
    const handleCheck = checkbox => {
      this.values.update(values => {
        if (checkbox.checked) {
          values.add(checkbox.value());
        } else {
          values.delete(checkbox.value());
        }
        this.updateControl(values);
        return values;
      });
    };
    const changeHandlers = Array.from(checkboxes.values()).map(checkbox => {
      return checkbox.changed.asObservable().pipe(tap(() => handleCheck(checkbox)));
    });
    merge(...changeHandlers).pipe(untilDestroyed(this)).pipe(takeUntil(this.checkboxesListUpdated)).subscribe();
  }
  static {
    this.ɵfac = function WlcmCheckboxGroupComponent_Factory(t) {
      return new (t || WlcmCheckboxGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmCheckboxGroupComponent,
      selectors: [["wlcm-checkbox-group"]],
      contentQueries: function WlcmCheckboxGroupComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.checkboxes, WlcmCheckboxComponent, 4);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostAttrs: [1, "wlcm-checkbox-group"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmCheckboxGroupComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmCheckboxGroupComponent),
        multi: true
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c6,
      decls: 1,
      vars: 0,
      template: function WlcmCheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c5);
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule]
    });
  }
};
WlcmCheckboxGroupComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [])], WlcmCheckboxGroupComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmCheckboxGroupComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-checkbox-group',
      host: {
        class: 'wlcm-checkbox-group'
      },
      standalone: true,
      imports: [CommonModule],
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmCheckboxGroupComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmCheckboxGroupComponent),
        multi: true
      }],
      template: "<ng-content select=\"wlcm-checkbox\"></ng-content>\n"
    }]
  }], () => [], null);
})();
class WlcmAutocompleteSuffixComponent {
  constructor() {
    this.autocomplete = input.required();
    this.WlcmIconName = WlcmIconName;
  }
  static {
    this.ɵfac = function WlcmAutocompleteSuffixComponent_Factory(t) {
      return new (t || WlcmAutocompleteSuffixComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmAutocompleteSuffixComponent,
      selectors: [["wlcm-autocomplete-suffix"]],
      hostAttrs: [1, "wlcm-autocomplete-suffix"],
      inputs: {
        autocomplete: [i0.ɵɵInputFlags.SignalBased, "autocomplete"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[4, "rxLet"], [3, "wlcmIcon"], [3, "hidden"]],
      template: function WlcmAutocompleteSuffixComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, WlcmAutocompleteSuffixComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("rxLet", i0.ɵɵpipeBind1(1, 1, ctx.autocomplete().loading$));
        }
      },
      dependencies: [CommonModule, i1.AsyncPipe, WlcmIconDirective, WlcmLoaderComponent, RxLet]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmAutocompleteSuffixComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-autocomplete-suffix',
      host: {
        class: 'wlcm-autocomplete-suffix'
      },
      standalone: true,
      imports: [CommonModule, WlcmIconDirective, WlcmLoaderComponent, RxLet],
      template: "<ng-container *rxLet=\"autocomplete().loading$ | async; let loading\">\n  @if (!loading) {\n    <ng-container [wlcmIcon]=\"WlcmIconName.CHEVRON_DOWN\"></ng-container>\n  }\n\n  <wlcm-loader [hidden]=\"!loading\"></wlcm-loader>\n</ng-container>\n"
    }]
  }], null, null);
})();
class WlcmSelectInputBinderDirective {
  static {
    this.ɵfac = function WlcmSelectInputBinderDirective_Factory(t) {
      return new (t || WlcmSelectInputBinderDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmSelectInputBinderDirective,
      selectors: [["", "wlcmSelectInputBinder", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const select = inject(WlcmSelectComponent, {
            self: true
          });
          return {
            bind: () => select
          };
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmSelectInputBinderDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[wlcmSelectInputBinder]',
      providers: [{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const select = inject(WlcmSelectComponent, {
            self: true
          });
          return {
            bind: () => select
          };
        }
      }]
    }]
  }], null, null);
})();
const PANEL_CLASS = 'wlcm-select-panel';
const DEFAULT_CONFIG = {
  overlayPanelClass: [PANEL_CLASS]
};
const mergeConfig = config => {
  const customizer = (_default, _new) => {
    if (Array.isArray(_default)) return _default.concat(_new);
  };
  return mergeWith({
    ...DEFAULT_CONFIG
  }, config, customizer);
};
let WlcmSelectComponent = class WlcmSelectComponent {
  openPanel() {
    this.matSelect.open();
  }
  constructor(zone, changeDetectorRef, scrollDetectionApi, formField, parentFormMember) {
    this.zone = zone;
    this.changeDetectorRef = changeDetectorRef;
    this.scrollDetectionApi = scrollDetectionApi;
    this.formField = formField;
    this.parentFormMember = parentFormMember;
    this.multiple = false;
    this.placeholder = 'Not selected';
    this.canSelect = _ => true;
    this.queryParams = DEFAULT_QUERY_PARAMS;
    this.paginated = input(false);
    this.options = input([]);
    this.paginatedOptions = signal([]);
    this.selectOptionFormat = input('ValueOnly');
    this.optionTemplate = input(null);
    this.triggerTemplate = input();
    this.isOptionDisabled = input(() => false);
    this.selectionChange = output();
    this.opened = output();
    this.closed = output();
    this.value = signal(null);
    this.disabled = signal(false);
    this.WlcmIconName = WlcmIconName;
    this._paginatedData = null;
    this._previousValue = null;
    this.computedOptions = computed(() => {
      return this.paginated() ? this.paginatedOptions() : this.options();
    });
    this.matOptions = viewChildren(MatOption);
    this._focusStream$ = new BehaviorSubject(EMPTY);
    this._blurStream$ = new BehaviorSubject(EMPTY);
    this.focus$ = this._focusStream$.pipe(switchMap(stream => stream));
    this.blur$ = this._blurStream$.pipe(switchMap(stream => stream));
    this.selected$ = new Subject();
    this.compareWith = (optionA, optionB) => {
      return (optionA?.value || optionA) === (optionB?.value || optionB);
    };
    this.focus = () => this.matSelect.open();
    this.isFocused = () => this.isOpen;
    if (this.parentFormMember) {
      this.handleStateChange();
    }
    effect(() => {
      if (this.paginated()) this.loadPaginatedOptions();
    }, {
      allowSignalWrites: true
    });
    effect(() => {
      if (this.isOptionDisabled() && this.computedOptions().length > 0) {
        this.updateOptionsStatus();
      }
    });
  }
  ngAfterViewInit() {
    const formField = this.formField.inputContainer;
    if (formField) {
      this.matSelect._preferredOverlayOrigin = new CdkOverlayOrigin(this.formField.inputContainer);
    }
    this._focusStream$.next(this.matSelect._openedStream);
    this._blurStream$.next(this.matSelect._closedStream);
  }
  select(event) {
    this.selected$.next();
    this.matSelect.value = event.value;
    this.validateSelectedOption(event.value).pipe(takeUntil(this.selected$)).subscribe(canSelect => {
      if (!canSelect) return this.revertPreviousValue();
      this.updateValue(event.value);
      this._changed?.(event.value);
      this._validatorChanged?.();
      this.selectionChange.emit(event);
    });
  }
  revertPreviousValue() {
    this.matSelect.value = this._previousValue;
  }
  updateValue(value) {
    this.value.set(value);
    this._previousValue = value;
  }
  validateSelectedOption(value) {
    return new Observable(observer => {
      const response = this.canSelect(value);
      const source = response instanceof Observable ? response.pipe(first()) : of(response);
      source.subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }
  panelOpened() {
    this.opened.emit();
    this.handleScrollDown();
  }
  panelClosed() {
    this.closed.emit();
    this._touched?.();
  }
  updateOptionsStatus() {
    this.matOptions().forEach(matOption => {
      matOption.disabled = this.isOptionDisabled()(matOption.value);
    });
  }
  writeValue(value) {
    this.updateValue(value);
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  registerOnValidatorChange(callback) {
    this._validatorChanged = callback;
  }
  setDisabledState(isDisabled) {
    this.disabled.set(isDisabled);
  }
  validate() {
    return null;
  }
  get isOpen() {
    return this.matSelect?.panelOpen ?? false;
  }
  loadPaginatedOptions() {
    this.fetchPaginatedOptions({
      ...this.queryParams,
      page: 1
    }).pipe(untilDestroyed(this), first()).subscribe(paginatedData => {
      this._paginatedData = paginatedData;
      this.paginatedOptions.set(paginatedData.data);
    });
  }
  loadMorePaginatedOptions() {
    if (!this._paginatedData || this._paginatedData.currPage + 1 > this._paginatedData.totalPages) return;
    this.queryParams.page++;
    this.fetchPaginatedOptions({
      ...this.queryParams
    }).pipe(untilDestroyed(this), first()).subscribe(paginatedData => {
      this._paginatedData = paginatedData;
      this.paginatedOptions.update(options => [...options, ...paginatedData.data]);
    });
  }
  handleScrollDown() {
    const element = document.querySelector('.mat-mdc-select-panel');
    this.zone.runOutsideAngular(() => {
      this.scrollDetectionApi.onScrolledDown(element).subscribe(() => {
        this.zone.run(() => this.loadMorePaginatedOptions());
      });
    });
  }
  handleStateChange() {
    this.parentFormMember.stateChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this._validatorChanged?.();
      this.changeDetectorRef.markForCheck();
    });
  }
  static {
    this.ɵfac = function WlcmSelectComponent_Factory(t) {
      return new (t || WlcmSelectComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1$3.ScrollDetectionApi), i0.ɵɵdirectiveInject(WLCM_FORM_FIELD), i0.ɵɵdirectiveInject(WLCM_FORM_CONTROL, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmSelectComponent,
      selectors: [["wlcm-select"]],
      viewQuery: function WlcmSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.matOptions, MatOption, 5);
          i0.ɵɵviewQuery(MatSelect, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matSelect = _t.first);
        }
      },
      hostBindings: function WlcmSelectComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function WlcmSelectComponent_click_HostBindingHandler() {
            return ctx.openPanel();
          });
        }
      },
      inputs: {
        multiple: "multiple",
        placeholder: "placeholder",
        fetchPaginatedOptions: "fetchPaginatedOptions",
        canSelect: "canSelect",
        queryParams: "queryParams",
        paginated: [i0.ɵɵInputFlags.SignalBased, "paginated"],
        options: [i0.ɵɵInputFlags.SignalBased, "options"],
        selectOptionFormat: [i0.ɵɵInputFlags.SignalBased, "selectOptionFormat"],
        optionTemplate: [i0.ɵɵInputFlags.SignalBased, "optionTemplate"],
        triggerTemplate: [i0.ɵɵInputFlags.SignalBased, "triggerTemplate"],
        isOptionDisabled: [i0.ɵɵInputFlags.SignalBased, "isOptionDisabled"]
      },
      outputs: {
        selectionChange: "selectionChange",
        opened: "opened",
        closed: "closed"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([WLCM_FORM_CONTROL_PROVIDER, {
        provide: MAT_SELECT_CONFIG,
        useFactory: () => {
          const wlcmConfig = inject(WLCM_SELECT_CONFIG, {
            optional: true
          }) ?? {};
          return mergeConfig(wlcmConfig);
        }
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmSelectComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmSelectComponent),
        multi: true
      }]), i0.ɵɵHostDirectivesFeature([WlcmSelectInputBinderDirective]), i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 14,
      consts: [["selectComponent", ""], [3, "selectionChange", "opened", "closed", "value", "disabled", "multiple", "disableRipple", "placeholder", "hideSingleSelectionIndicator", "ngClass", "compareWith"], [3, "value", 4, "ngFor", "ngForOf"], [1, "wlcm-select-arrow"], [3, "wlcmIcon", "wlcmIconStopPropagation"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "value"]],
      template: function WlcmSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "mat-select", 1, 0);
          i0.ɵɵlistener("selectionChange", function WlcmSelectComponent_Template_mat_select_selectionChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.select($event));
          })("opened", function WlcmSelectComponent_Template_mat_select_opened_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.panelOpened());
          })("closed", function WlcmSelectComponent_Template_mat_select_closed_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.panelClosed());
          });
          i0.ɵɵtemplate(2, WlcmSelectComponent_Conditional_2_Template, 2, 4, "mat-select-trigger")(3, WlcmSelectComponent_mat_option_3_Template, 3, 2, "mat-option", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 3);
          i0.ɵɵelementContainer(5, 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const selectComponent_r3 = i0.ɵɵreference(1);
          i0.ɵɵproperty("value", ctx.value())("disabled", ctx.disabled())("multiple", ctx.multiple)("disableRipple", true)("placeholder", ctx.placeholder)("hideSingleSelectionIndicator", true)("ngClass", i0.ɵɵpureFunction1(12, _c7, selectComponent_r3.focused))("compareWith", ctx.compareWith);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(2, ctx.triggerTemplate() ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.computedOptions());
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("wlcmIcon", ctx.WlcmIconName.CHEVRON_DOWN)("wlcmIconStopPropagation", false);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgForOf, i1.NgTemplateOutlet, MatSelectModule, i4$1.MatSelect, i4$1.MatSelectTrigger, i3.MatOption, ReactiveFormsModule, WlcmIconDirective],
      changeDetection: 0
    });
  }
};
WlcmSelectComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [NgZone, ChangeDetectorRef, ScrollDetectionApi, Object, Object])], WlcmSelectComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmSelectComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-select',
      standalone: true,
      imports: [CommonModule, MatSelectModule, ReactiveFormsModule, WlcmIconDirective, WlcmSelectInputBinderDirective],
      changeDetection: ChangeDetectionStrategy.OnPush,
      hostDirectives: [WlcmSelectInputBinderDirective],
      providers: [WLCM_FORM_CONTROL_PROVIDER, {
        provide: MAT_SELECT_CONFIG,
        useFactory: () => {
          const wlcmConfig = inject(WLCM_SELECT_CONFIG, {
            optional: true
          }) ?? {};
          return mergeConfig(wlcmConfig);
        }
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmSelectComponent),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmSelectComponent),
        multi: true
      }],
      template: "<mat-select\n  #selectComponent\n  [value]=\"value()\"\n  [disabled]=\"disabled()\"\n  [multiple]=\"multiple\"\n  [disableRipple]=\"true\"\n  [placeholder]=\"placeholder\"\n  [hideSingleSelectionIndicator]=\"true\"\n  [ngClass]=\"{ focused: selectComponent.focused }\"\n  (selectionChange)=\"select($event)\"\n  [compareWith]=\"compareWith\"\n  (opened)=\"panelOpened()\"\n  (closed)=\"panelClosed()\"\n>\n  @if (triggerTemplate()) {\n    <mat-select-trigger>\n      <ng-container\n        *ngTemplateOutlet=\"\n          triggerTemplate()!;\n          context: $any({ $implicit: selectComponent.selected })\n        \"\n      ></ng-container>\n    </mat-select-trigger>\n  }\n\n  <mat-option\n    *ngFor=\"let option of computedOptions()\"\n    [value]=\"selectOptionFormat() === 'CompleteOption' ? option : option.value\"\n  >\n    @if (optionTemplate()) {\n      <ng-container\n        *ngTemplateOutlet=\"\n          optionTemplate();\n          context: $any({ $implicit: option })\n        \"\n      ></ng-container>\n    } @else {\n      {{ option.viewValue }}\n    }\n  </mat-option>\n</mat-select>\n\n<div class=\"wlcm-select-arrow\">\n  <ng-container\n    [wlcmIcon]=\"WlcmIconName.CHEVRON_DOWN\"\n    [wlcmIconStopPropagation]=\"false\"\n  ></ng-container>\n</div>\n"
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1$3.ScrollDetectionApi
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_FORM_FIELD]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [WLCM_FORM_CONTROL]
    }]
  }], {
    multiple: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    fetchPaginatedOptions: [{
      type: Input
    }],
    canSelect: [{
      type: Input
    }],
    queryParams: [{
      type: Input
    }],
    matSelect: [{
      type: ViewChild,
      args: [MatSelect]
    }],
    openPanel: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class WlcmFormFieldHintDirective {
  static {
    this.ɵfac = function WlcmFormFieldHintDirective_Factory(t) {
      return new (t || WlcmFormFieldHintDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmFormFieldHintDirective,
      selectors: [["", "wlcmFormFieldHint", ""]],
      hostAttrs: [1, "wlcm-hint"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormFieldHintDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmFormFieldHint]',
      host: {
        class: 'wlcm-hint'
      },
      standalone: true
    }]
  }], null, null);
})();
const directives = [WlcmInputDirective, WlcmFormFieldCustomContainerDirective, WlcmFormFieldSuffixDirective, WlcmFormFieldPrefixDirective, WlcmFormFieldHintDirective];
const components = [WlcmFormFieldComponent, WlcmAutocompleteComponent, WlcmAutocompleteSuffixComponent, WlcmCheckboxGroupComponent, WlcmCheckboxComponent, WlcmSelectComponent, WlcmLabelComponent];
class WlcmFormsModule {
  static {
    this.ɵfac = function WlcmFormsModule_Factory(t) {
      return new (t || WlcmFormsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WlcmFormsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: MAT_RADIO_DEFAULT_OPTIONS,
        useValue: {
          color: 'primary'
        }
      }],
      imports: [components, MatRadioModule, MatRadioModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmFormsModule, [{
    type: NgModule,
    args: [{
      imports: [...components, ...directives, MatRadioModule],
      exports: [...components, ...directives, MatRadioModule],
      providers: [{
        provide: MAT_RADIO_DEFAULT_OPTIONS,
        useValue: {
          color: 'primary'
        }
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { WLCM_ERRORS, WLCM_FORM, WLCM_FORM_CONTROL, WLCM_FORM_CONTROL_PROVIDER, WLCM_FORM_FIELD, WLCM_FORM_FIELD_INPUT_CLASS, WLCM_FORM_PROVIDER, WLCM_INPUT, WLCM_INPUT_BINDER, WLCM_MAPS_API_ERROR, WLCM_PRIORITY_ERRORS, WLCM_SELECT_CONFIG, WlcmAutocomplete, WlcmAutocompleteComponent, WlcmAutocompleteSuffixComponent, WlcmCheckboxComponent, WlcmCheckboxGroupComponent, WlcmErrorComponent, WlcmFormFieldComponent, WlcmFormFieldCustomContainerDirective, WlcmFormFieldHintDirective, WlcmFormFieldPrefixDirective, WlcmFormFieldSuffixDirective, WlcmFormsModule, WlcmInputDirective, WlcmLabelComponent, WlcmPlacesApi, WlcmSelectComponent, WlcmSelectInputBinderDirective };
